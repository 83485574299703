import { PagePath } from '@routes/appUrls';
import MobileNavLink from './components/MobileNavLink';

const MobileNavBar = () => {
    return (
        <div className='absolute md:hidden z-50 bottom-0 left-2 w-full'>
            <MobileNavLink to={PagePath.survival} label='Survival' />
            {/* <MobileNavLink to={PagePath.creative} label='Creative' /> */}
        </div>
    );
};

export default MobileNavBar;