import { PagePath } from '@routes/appUrls';
import Server from './components/Server';

const Dashboard = () => {
    return (
        <div className='absolute w-full h-full bg-gray-900'>
            <div className='flex flex-col gap-5 items-center justify-center w-full h-full'>
                <h1 className='text-5xl text-white'>Dashboard</h1>
                <div className='flex flex-row w-1/3 justify-center gap-5'>
                    <Server to={PagePath.survival} label='Survival' />
                    {/* <Server to={PagePath.creative} label='Creative' /> */}
                </div>
            </div>
        </div>
    );
};

export default Dashboard;