import Dynmap from '@components/features/dynmap/Dynmap';
import MobileNavBar from '@components/features/navBar/MobileNavBar';
import NavBar from '@components/features/navBar/NavBar';
import ServerInfo from '@components/features/serverInfo/ServerInfo';
import ServerType from '@ServerType';
import { useEffect, useState } from 'react';

type Props = {
    ip: string;
};

const ServerPage = ({ ip }: Props) => {
    const [server, setServer] = useState({} as ServerType);

    useEffect(() => {
        fetch(`https://api.mcsrvstat.us/2/${ip}`).then
            ((response: Response) => response.json()).then((data: any) => {
                setServer(data);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ip]);

    return (
        <div className='absolute top-0 bottom-0 w-full h-full pt-24 pb-4 pl-12 pr-12 bg-gray-900 flex flex-row overflow-hidden gap-5'>
            <NavBar />
            <MobileNavBar />
            <div className='w-1/2 md:flex flex-col hidden'>
                <ServerInfo server={server} />
            </div>
            <Dynmap ip={ip} />
        </div>
    );
};

export default ServerPage;