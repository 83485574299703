import Dashboard from '@components/pages/dashboard/Dashboard';
import ServerPage from '@components/pages/serverPage/ServerPage';
import RoutesWrapper from '@components/primitives/routesWrapper/RoutesWrapper';
import { PagePath } from '@routes/appUrls';
import { BrowserRouter, Route } from 'react-router-dom';

const App = () => {
  return (
    <BrowserRouter>
      <RoutesWrapper>
        <Route path='/' element={<Dashboard />} />
        <Route path={PagePath.dashboard} element={<Dashboard />} />
        <Route path={PagePath.survival} element={<ServerPage ip='play.martvanweeghel.nl' />} />
        {/* <Route path={PagePath.creative} element={<ServerPage ip='creative.martvanweeghel.nl' />} /> */}
      </RoutesWrapper>
    </BrowserRouter>
  );
};

export default App;
