import { Link } from 'react-router-dom';

type Props = {
    to: string;
    label: string;
};

const Server = ({ to, label }: Props) => {
    return (
        <Link to={to} className='justify-center items-center border text-black p-5 flex flex-row md:w-1/2 gap-2 border-gray-500 rounded-md shadow-inner shadow-gray-500 bg-gray-200 hover:bg-gray-300'>
            <h1 className='text-xl'>{label}</h1>
        </Link>
    );
};

export default Server;  