import { PagePath } from '@routes/appUrls';
import NavLink from './components/NavLink';

const NavBar = () => {
    return (
        <ul className="md:absolute hidden top-5 w-11/12 left-20 md:flex flex-wrap text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:border-gray-700 dark:text-gray-400">
            <NavLink to={PagePath.survival} label='Survival' />
            {/* <NavLink to={PagePath.creative} label='Creative' /> */}
        </ul>
    );
};

export default NavBar;