import { ReactComponent as FullScreenSVG } from '@images/arrows-fullscreen.svg';

type Props = {
    ip: string;
};

const Dynmap = ({ ip }: Props) => {
    const toggleFullScreen = () => {
        const elem = document.getElementById('map') as any;
        if (elem.requestFullscreen) {
            elem.requestFullscreen();
        } else if (elem.webkitRequestFullscreen) { /* Safari */
            elem.webkitRequestFullscreen();
        } else if (elem.msRequestFullscreen) { /* IE11 */
            elem.msRequestFullscreen();
        }
    };

    // const dynmapUrl = ip === 'play.martvanweeghel.nl' ? 'https://dynmap.martvanweeghel.nl' : `https://creativemap.martvanweeghel.nl`;

    return (
        <div className='absolute md:relative top-0 left-0 w-full md:h-5/6 h-full flex flex-col gap-5'>
            <div className='md:flex items-center gap-5 hidden'>
                <h1 className='text-white text-4xl '>Dynmap</h1>
                <h1 onClick={() => toggleFullScreen()} className='p-0 m-0 cursor-pointer hover:text-gray-300 text-white'><FullScreenSVG /></h1>
            </div>
            {/* <iframe id='map' src={dynmapUrl} className='z-20 relative w-full h-full rounded-lg' title='Dynmap'></iframe> */}
            <div className='z-20 relative w-full h-full rounded-lg text-white text-2xl'>
                In onderhoud
            </div>
        </div >
    );
};

export default Dynmap;