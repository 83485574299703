import {
    Routes,
    Route,
} from 'react-router-dom';
import ErrorPage from '@components/pages/errorPage/ErrorPage';
import { ReactChild } from 'react';

type Props = {
    children: ReactChild | ReactChild[];
};
const RoutesWrapper = ({ children }: Props) => {
    return (
        <>
            <Routes>
                {children}
                <Route path="*" element={<ErrorPage error={404} />} />
            </Routes>
        </>
    );
};

export default RoutesWrapper;