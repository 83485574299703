import { PagePath } from '@routes/appUrls';
import { Link } from 'react-router-dom';

type Props = {
    error: number;
};
const ErrorPage = ({ error }: Props) => {
    return (
        <div className='absolute w-full h-full bg-gray-900'>
            <div className='flex flex-col gap-5 justify-center items-center text-white h-full'>
                <h1 className='text-8xl'>{error}</h1>
                <p className='md:text-2xl text-md'>
                    The page you are looking for does not exist.
                </p>
                <div className='md:w-1/3 w-1/2 flex'>
                    <Link to={PagePath.dashboard} className='bg-green-500 hover:bg-green-700 p-2 rounded-md w-full'>Dashboard</Link>
                </div>
                <div className='md:w-1/3 w-1/2 flex gap-5'>
                    <Link to={PagePath.survival} className='bg-red-500 hover:bg-red-700 p-2 rounded-md w-full'>Survival</Link>
                    <Link to={PagePath.creative} className='bg-blue-500 hover:bg-blue-700 p-2 rounded-md w-full'>Creative</Link>
                </div>
            </div>
        </div>
    );
};

export default ErrorPage;