const Offline = () => {
    return (
        <div className='border text-black p-5 flex flex-row w-[32rem] gap-2 border-gray-500 rounded-md shadow-inner shadow-gray-500 bg-gray-200'>
            <div className='flex flex-col'>
                <h1>Offline</h1>
            </div>
        </div>
    );
};

export default Offline;