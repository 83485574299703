import ServerType from '@ServerType';
import Offline from './components/Offline';
import Online from './components/Online';

type Props = {
    server: ServerType;
};
const ServerInfo = ({ server }: Props) => {
    return (
        <div className='cursor-default  text-white flex flex-col gap-5'>
            <h1 className='text-4xl'>Server Info</h1>
            <div>
                {server.online ? (
                    <Online server={server} />
                ) : (
                    <Offline />
                )}
            </div>
        </div>
    );
};

export default ServerInfo;