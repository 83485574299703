import ServerType from '@ServerType';
import { useEffect } from 'react';

type Props = {
    server: ServerType;
};

const Online = ({ server }: Props) => {
    useEffect(() => {
        if (!server) return;

        document.getElementById('motd').innerHTML = server.motd.html.join('<br/>');
    }, [server]);

    return (
        <div className='border text-black p-5 flex flex-row w-full gap-2 border-gray-500 rounded-md shadow-inner shadow-gray-500 bg-gray-200'>
            <div className='flex flex-col items-center h-full'>
                <img src={server.icon} className='w-16 h-16' alt="Server" />
            </div>
            <div className='flex flex-col'>
                <h1>{server.hostname}</h1>
                <div id='motd'></div>
            </div>
            <div className='flex flex-col ml-auto'>
                {server.players.online} / {server.players.max} players
            </div>
        </div>
    );
};

export default Online;