import { Link, useMatch, useResolvedPath } from 'react-router-dom';

type Props = {
    to: string;
    label: string;
};

const NavLink = ({ to, label }: Props) => {
    let resolved = useResolvedPath(to);
    let match = useMatch({ path: resolved.pathname });


    return (
        <Link to={to} className={`mr-2 inline-block p-4 text-white bg-gray-800 rounded-t-lg ${match ? 'bg-gray-300 text-black' : null}`}>
            {label}
        </Link>
    );
};

export default NavLink;